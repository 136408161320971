import {
    Text,
    Loader,
  } from "@aws-amplify/ui-react";

export const Loading = ({ isLoading, text }) => {
    if (!isLoading) return null;
  
    return (
      <div>
        <Text>{text}</Text>
        <Loader size="large" />
      </div>
    );
  };


  