import React from "react";
import { Button } from "@aws-amplify/ui-react";
import { Storage, I18n } from "aws-amplify";

function HelpButton() {
  const handleHelpClick = () => {
    downloadFile(getLocalizedHelpGuideFilename());
  };

  function getLocalizedHelpGuideFilename(){
    const language = navigator.language || navigator.userLanguage;
    if (language.startsWith("de")) {
        return "Help_Valeo_DE.pdf";
    } else {
        return "Help_Valeo_EN.pdf";
    }
  }

  async function downloadFile(objectID) {
    try {
      if (!objectID) {
        throw new Error(I18n.get("Invalid objectID"));
      }
      const fileKey = objectID;
      const result = await Storage.get(fileKey, { download: true });
      if (result?.Body) {
        await downloadBlob(result.Body, fileKey);
      } else {
        throw new Error(I18n.get("File not found or empty"));
      }
    } catch (error) {
      console.error(I18n.get("Download error:"), error);
    }
  }

  async function downloadBlob(blob, filename) {
    try {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      await new Promise((resolve, reject) => {
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
            resolve();
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
      });
    } catch (error) {
      console.error("DownloadBlob error:", error);
    }
  }  

  return (
    <Button
      variation="link"
      className="help-button"
      onClick={handleHelpClick}
    >
      {I18n.get("Help")}
    </Button>
  );
}

export default HelpButton;
