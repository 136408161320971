import React, { useState, useEffect } from "react";
import { API, Storage, Auth, I18n } from "aws-amplify";
import {
  Grid,
  Card,
  Text,
  SelectField,
  RadioGroupField,
  Radio,
  Button,
  Input,
  Message,
} from "@aws-amplify/ui-react";
import { v4 } from "uuid";
import { QuarterSpliltScreen } from "./QuarterSplitScreenLayout";
import FieldSearch from "./FieldSearch";
import SearchResults from "./SearchResult";
import { PaginationComponent } from "./Pagination";
import { Loading } from "./Loading";
import { PDFDocument } from "pdf-lib";

export const SearchBar = () => {
  const [results, setResults] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [resultType, setResultType] = useState("");
  const [searchType, setSearchType] = useState("und");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortCriteria, setSortCriteria] = useState({
    column: null,
    order: "asc",
  });
  const resultPerPage = 50;

  // set up filters
  const initFilterState = () => ({
    field: "",
    option: [],
    selectedOption: "",
    inputValue: "",
    showDateInput: false,
  });

  const [filter1, setFilter1] = useState(initFilterState());
  const [filter2, setFilter2] = useState(initFilterState());
  const [filter3, setFilter3] = useState(initFilterState());
  const [filter4, setFilter4] = useState(initFilterState());
  const [filter5, setFilter5] = useState(initFilterState());

  // get document data
  const fetchData = async (query, page) => {
    setDataIsLoading(true);
    try {
      const requestData = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          query: query,
          size: resultPerPage,
          from: resultPerPage * (page - 1),
          contentType: resultType,
          conditionType: searchType,
          filter_1: JSON.stringify(makeSearchFilter(filter1)),
          filter_2: JSON.stringify(makeSearchFilter(filter2)),
          filter_3: JSON.stringify(makeSearchFilter(filter3)),
          filter_4: JSON.stringify(makeSearchFilter(filter4)),
          filter_5: JSON.stringify(makeSearchFilter(filter5)),
          sortColumn: sortCriteria.column,
          sortOrder: sortCriteria.order
        },
      };
      const data = await API.get("documents", "/invoices", requestData);
      const resultData = data["hits"];
      setResults(resultData);
      const totalCount = data["total"].value;
      const totalPages = Math.ceil(totalCount / resultPerPage);
      setTotalPages(totalPages);
      setShowErrorMessage(false);
    } catch (error) {
      setShowErrorMessage(true);
      console.log(error);
    }
    setDataIsLoading(false);
  };

  function makeSearchFilter(filter) {
    let searchFilter = {};
    searchFilter.field = filter.field;
    searchFilter.inputValue = filter.inputValue;
    switch (filter.selectedOption) {
      case "Equals":
      case "Ist gleich":
        searchFilter.selectedOption = "Ist gleich";
        break;
      case "Earlier than":
      case "Früher als":
        searchFilter.selectedOption = "Früher als";
        break;
      case "Later than":
      case "Später als":
        searchFilter.selectedOption = "Später als";
        break;
      case "Does not equal":
      case "Ist nicht gleich":
        searchFilter.selectedOption = "Ist nicht gleich";
        break;
      case "Greater than":
      case "Größer als":
        searchFilter.selectedOption = "Größer als";
        break;
      case "Less than":
      case "Kleiner als":
        searchFilter.selectedOption = "Kleiner als";
        break;
      case "Contains":
      case "Enthält":
        searchFilter.selectedOption = "Enthält";
        break;
      case "Does not contain":
      case "Enthält nicht":
        searchFilter.selectedOption = "Enthält nicht";
        break;
      case "Begins with":
      case "Beginnt mit":
        searchFilter.selectedOption = "Beginnt mit";
        break;
      default:
        searchFilter.selectedOption = "";
    }
    return searchFilter;
  }

  //handle sort
  const handleSortChange = (column) => {
    if (sortCriteria.column === column) {
      setSortCriteria({
        column: column,
        order: sortCriteria.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortCriteria({
        column: column,
        order: "asc",
      });
    }
  };

  useEffect(() => {
    if (hasSubmitted) {
      fetchData(searchText, currentPage);
    } else {
      fetchData("", currentPage);
    }
  }, [currentPage, hasSubmitted, sortCriteria]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    setCurrentPage(1);
    fetchData(searchText, 1);
  };

  // bulk download
  async function downloadZip() {
    if (selectedFiles.length === 0) {
      console.error(I18n.get("No files selected."));
      window.alert(I18n.get("Please select a file!"));
      return;
    }
    setDownloadIsLoading(true);

    let formattedDocList;
    if (process.env.REACT_APP_TEST === "true") {
      formattedDocList = [
        "public/PDFFiles/pdfFile-test.pdf",
        "public/SignatureFiles/signatureFiles-test.pdf.pkcs7",
        "public/SignatureReports/signatureReport-test.html",
        "public/IDOCFiles/IDOC-test.idoc",
      ].join(",");
    } else {
      formattedDocList = selectedFiles
        .flatMap((file) => [
          `public/PDFFiles/${file.pdf}`,
          `public/SignatureFiles/${file.signature}`,
          `public/SignatureReports/${file.report}`,
          `public/IDOCFiles/${file.idoc}`,
        ])
        .join(",");
    }

    const zipKey = `tmp/${v4().replace(/-/g, "")}.zip`;
    const requestData = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: {
        docList: formattedDocList,
        zipFileName: `public/${zipKey}`,
      },
    };
    try {
      await API.get("documents", "/zip", requestData);
      const result = await Storage.get(zipKey, { download: true });
      if (result?.Body) {
        await downloadBlob(result.Body, zipKey);
        setSelectedFiles([]);
      } else {
        throw new Error(I18n.get("File not found or empty"));
      }
    } catch (error) {
      console.error(I18n.get("DownloadZip error:"), error);
    } finally {
      setDownloadIsLoading(false);
    }
  }

  async function downloadFile(objectID) {
    setDownloadIsLoading(true);
    try {
      if (!objectID) {
        throw new Error(I18n.get("Invalid objectID"));
      }
      const fileKey = objectID;
      const result = await Storage.get(fileKey, { download: true });
      if (result?.Body) {
        await downloadBlob(result.Body, fileKey);
      } else {
        throw new Error(I18n.get("File not found or empty"));
      }
    } catch (error) {
      console.error(I18n.get("Download error:"), error);
    } finally {
      setDownloadIsLoading(false);
    }
  }

  async function downloadBlob(blob, filename) {
    try {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      await new Promise((resolve, reject) => {
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
            resolve();
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
      });
    } catch (error) {
      console.error(I18n.get("DownloadBlob error:"), error);
    }
  }

  const printPDF = async (input) => {
    try {
      let blob;
      if (input instanceof Blob) {
        blob = input;
      } else {
        const finalPDFFileName =
          process.env.REACT_APP_TEST === "true" 
            ? "pdfFile-test.pdf" 
            : input;
        const signedURL = await Storage.get(`PDFFiles/${finalPDFFileName}`);
        const response = await fetch(signedURL);
        if (!response.ok) {
          console.error(
            I18n.get("Failed to fetch the PDF. Response status:"),
            response.status
          );
          return;
        }
        blob = new Blob([await response.arrayBuffer()], {
          type: "application/pdf",
        });
      }
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const objectURL = URL.createObjectURL(blob);
      iframe.src = objectURL;

      iframe.onload = () => {
        iframe.contentWindow.print();
        iframe.contentWindow.onafterprint = () => {
          URL.revokeObjectURL(objectURL);
          document.body.removeChild(iframe);
        };
      };
    } catch (error) {
      console.error(I18n.get("Error while trying to print the PDF:"), error);
    }
  };

  // Merge selected PDFs
  const mergePDFs = async (pdfFiles) => {
    const mergedPdf = await PDFDocument.create();
    for (const file of pdfFiles) {
      const fileArrayBuffer = await fetch(file).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(fileArrayBuffer);
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  };

  // Print merged PDFs
  const printMergedPDFs = async (pdfFileNames) => {
    try {
      const signedURLs = await Promise.all(
        pdfFileNames.map((fileName) => Storage.get(`PDFFiles/${fileName}`))
      );
      const mergedPdfBlob = new Blob([await mergePDFs(signedURLs)], {
        type: "application/pdf",
      });
      await printPDF(mergedPdfBlob);
    } catch (error) {
      console.error(
        I18n.get("Error while trying to print merged PDFs:"),
        error
      );
    }
  };

  // hanldes pagination
  const handleOnChange = (newPage, prevPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid>
      <Card>
        <Text className="search-title">
          {I18n.get("Find documents with...")}
        </Text>
        <QuarterSpliltScreen firstWeight={1} secondWeight={1}>
          <Text className="search-content">
            {I18n.get("All of these words:")}{" "}
          </Text>
          <Input
            placeholder={I18n.get("Search here...")}
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
          ></Input>
        </QuarterSpliltScreen>
      </Card>
      <Card>
        <Text className="search-title">{I18n.get("Narrow the search...")}</Text>
        <QuarterSpliltScreen firstWeight={1} secondWeight={1}>
          <Text className="search-content">{I18n.get("Result type")}</Text>
          <SelectField
            value={resultType}
            onChange={(e) => setResultType(e.target.value)}
          >
            <option value="">{I18n.get("All Documents")}</option>
            <option value="credit note">{I18n.get("Credit note")}</option>
            <option value="consi credit note">
              {I18n.get("Consi Credit note")}
            </option>
            <option value="debit note">{I18n.get("Debit note")}</option>
          </SelectField>
        </QuarterSpliltScreen>
      </Card>
      <Card>
        <Text className="search-title">
          {I18n.get("Which search method...")}
        </Text>
        <QuarterSpliltScreen firstWeight={1} secondWeight={1}>
          <Text className="search-content">{I18n.get("Search mode")}</Text>
          <RadioGroupField
            direction="row"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <Radio value="und">{I18n.get("And")}</Radio>
            <Radio value="oder">{I18n.get("Or")}</Radio>
          </RadioGroupField>
        </QuarterSpliltScreen>
      </Card>
      <Card>
        <Text className="search-title">
          {I18n.get("Add property restrictions...")}
        </Text>
        <FieldSearch filter={filter1} setFilter={setFilter1} showText={true} />
        <FieldSearch filter={filter2} setFilter={setFilter2} />
        <FieldSearch filter={filter3} setFilter={setFilter3} />
        <FieldSearch filter={filter4} setFilter={setFilter4} />
        <FieldSearch filter={filter5} setFilter={setFilter5} />
      </Card>
      <Button className="submit-button" onClick={handleSubmit}>
        {I18n.get("Search")}
      </Button>
      <Card className="result-container">
        {dataIsLoading || downloadIsLoading ? (
          <div className="loader">
            <Loading isLoading={dataIsLoading} text={I18n.get("Loading...")} />
            <Loading
              isLoading={downloadIsLoading}
              text={I18n.get("Downloading...")}
            />
          </div>
        ) : (
          <>
            {showErrorMessage && (
              <Message
                className="error-message"
                variation="filled"
                colorTheme="error"
              >
                {I18n.get("Invalid filter input")}
              </Message>
            )}
            <SearchResults
              results={results}
              downloadFile={downloadFile}
              downloadZip={downloadZip}
              printPDF={printPDF}
              printMergedPDFs={printMergedPDFs}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              onSortChange={handleSortChange}
              sortCriteria={sortCriteria}
            />
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handleOnChange}
            />
          </>
        )}
      </Card>
    </Grid>
  );
};
