import React from "react";
import { SelectField, Input, Text } from "@aws-amplify/ui-react";
import { QuarterSpliltScreen } from "./QuarterSplitScreenLayout";
import { I18n } from "aws-amplify";

function FieldSearch({ filter, setFilter, showText }) {
  const [displayValue, setDisplayValue] = React.useState("");
  const handleFieldChange = (event) => {
    const selectedValue = event.target.value;
    let newFilter = { ...filter };

    if (selectedValue === "eBoxValeoDate") {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Earlier than"),
          I18n.get("Later than"),
        ],
        showDateInput: true,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else if (selectedValue === "eBoxValeoAmount") {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Greater than"),
          I18n.get("Less than"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Contains"),
          I18n.get("Does not contain"),
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Begins with"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Contains"),
        inputValue: "",
      };
    }
    setFilter(newFilter);
  };

  const handleOptionChange = (event) => {
    setFilter({
      ...filter,
      selectedOption: event.target.value,
      inputValue: "",
    });
    setDisplayValue("");
  };

  //handles document state search filter
  //metadata does not allow search by key words
  //convert user input into metadata mapping to allow user-friendly search
  const handleInputChange = (event) => {
    const originalValue = event.target.value;
    let transformedValue = originalValue;
    const lowerCasedValue = originalValue.toLowerCase();

    if (["neu", "new"].includes(lowerCasedValue)) {
      transformedValue = "1";
    } else if (["gelesen", "read"].includes(lowerCasedValue)) {
      transformedValue = "2";
    } else if (["heruntergeladen", "downloaded"].includes(lowerCasedValue)) {
      transformedValue = "3";
    }

    setFilter({
      ...filter,
      inputValue: transformedValue,
    });
    setDisplayValue(originalValue);
  };

  return (
    <QuarterSpliltScreen
      firstWeight={1}
      secondWeight={1}
      thirdWeight={1}
      fourthWeight={1}
    >
      {showText && (
        <Text className="search-content">
          {I18n.get("Where the property...")}{" "}
        </Text>
      )}
      <SelectField value={filter.field} onChange={handleFieldChange}>
        <option value="select">{I18n.get("(Pick Property)")}</option>
        <option value="eBoxValeoAmount">{I18n.get("Amount")}</option>
        <option value="eBoxValeoCountryFrom">{I18n.get("Country from")}</option>
        <option value="eBoxValeoCountryTo">{I18n.get("Country to")}</option>
        <option value="eBoxValeoCreditNoteNumber">
          {I18n.get("Credit Note No.")}
        </option>
        <option value="eBoxValeoDate">{I18n.get("Date ")}</option>
        <option value="Title">{I18n.get("Document name")}</option>
        <option value="eBoxViewStatus">{I18n.get("Document state")}</option>
        <option value="eBoxValeoDunsNumber">{I18n.get("DUNS Number")}</option>

        <option value="eBoxValeoInternalCreditNoteNumber">
          {I18n.get("Internal Credit Note No.")}
        </option>
        <option value="eBoxValeoSuppliernumber">
          {I18n.get("Supplier Number")}
        </option>
        <option value="eBoxValeoTaxID">{I18n.get("TaxID")}</option>
        <option value="eBoxValeoCompany">{I18n.get("Valeo Company")}</option>
      </SelectField>
      <SelectField
        className="dependent-dropdown"
        value={filter.selectedOption}
        onChange={handleOptionChange}
      >
        {filter.option.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectField>
      {filter.showDateInput ? (
        <Input
          type="date"
          value={filter.inputValue}
          onChange={handleInputChange}
        />
      ) : (
        <Input
          type="text"
          value={displayValue}
          onChange={handleInputChange}
        />
      )}
    </QuarterSpliltScreen>
  );
}

export default FieldSearch;
