import React from "react";
import { Pagination } from "@aws-amplify/ui-react";


export const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleOnChange = (newPage, prevPage) => {
    onPageChange(newPage);
  };

  return (
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onNext={goToNextPage}
      onPrevious={goToPreviousPage}
      onChange={handleOnChange}
    />
  );
};