import '@aws-amplify/ui-react/styles.css';
import {
  Authenticator,
  View,
  translations,
  Divider,
  Button
} from '@aws-amplify/ui-react';
import './App.css';
import { Amplify, I18n } from 'aws-amplify';
import awsconfig from './aws-exports';
import * as React from 'react';
import { Logo } from './components/Logo';
import { SearchBar } from './components/SearchBar';
import SupportButton from './components/Support';
import HelpButton from "./components/Help";
import { languageDict } from './language-strings';

Amplify.configure(awsconfig);
Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
    },
  },
});

I18n.putVocabularies(translations);
I18n.putVocabularies(languageDict);

function App({ signOut }) {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <View className='App'>
          <div className='topbar-wrapper'>
            <Button
              variation='link'
              className='signout-button'
              onClick={signOut}
            >
              {I18n.get('Sign Out')}
            </Button>
            <SupportButton />
            <HelpButton />
          </div>
          <Divider orientation='horizontal' />
          <Logo />
          <SearchBar />
        </View>
      )}
    </Authenticator>
  );
}

export default App;
