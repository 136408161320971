// See https://docs.amplify.aws/lib/utilities/i18n/q/platform/js/#putvocabularies
export const languageDict = {
  de: {
    "Enter your Username": "Geben Sie Ihren Benutzernamen ein",
    "Sign Out": "Abmelden",
    "Support": "Unterstützung",
    "Please select a file!": "Bitte wählen Sie eine Datei aus!",
    "No files selected.": "Keine Dateien ausgewählt.",
    "File not found or empty": "Datei nicht gefunden oder leer",
    "DownloadZip error:": "DownloadZip-Fehler:",
    "Invalid objectID": "Ungültige Objekt-ID",
    "Download error:": "Download Fehler:",
    "DownloadBlob error:": "DownloadBlob-Fehler",
    "Failed to fetch the PDF. Response status:":
      "Das PDF konnte nicht abgerufen werden. Antwortstatus:",
    "Error while trying to print the PDF:":
      "Fehler beim Versuch, das PDF zu drucken:",
    "Error while trying to print merged PDFs:": 
    "Fehler beim Versuch, zusammengeführte PDFs zu drucken:",
    "Find documents with...": "Dokumente suchen mit ...",
    "Search here...": "Suche hier...",
    "Narrow the search...": "Die Suche einschränken ...",
    "Result type": "Ergebnistyp",
    "All Documents": "Alle Ergebnistypen",
    "All of these words:": "Alle folgenden Wörter:",
    "Consi Credit note": "Konsignationsgutschrift",
    "Debit note": "Belastungsanzeige",
    "Which search method...": "Wie soll gesucht werden ...",
    "Search mode": "Suchart",
    "And": "Und",
    'Or': "Oder",
    "Add property restrictions...":
      "Suchkriterien in Eigenschaft hinzufügen ...",
    "Search": "Suchen",
    "Loading...": "Wird geladen...",
    "Downloading...": "Wird heruntergeladen...",
    "Invalid filter input": "Ungültige Filtereingabe",
    "Where the property...": "Wenn die Eigenschaft...",
    "(Pick Property)": "(Eigenshaft auswählen)",
    "Country from": "Belegherkunftsland",
    "Amount": "Betrag",
    "Date ": "Datum",
    "Document name": "Dokumentname",
    "Document state": "Dokumentstatus",
    "DUNS Number": "DUNS",
    "Credit Note No.": "Gutschriftnummer",
    "Country to": "Land Belegempfänger",
    "Supplier Number": "Lieferantennummer",
    "Tax Id": "UmsatzsteuerID",
    "TaxID":"UmsatzsteuerID",
    "Valeo Company": "Valeo Werk",
    "Equals": "Ist gleich",
    "Earlier than": "Früher als",
    "Later than": "Später als",
    "Does not equal": "Ist nicht gleich",
    "Greater than": "Größer als",
    "Less than": "Kleiner als",
    "Contains": "Enthält",
    "Does not contain": "Enthält nicht",
    "Begins with": "Beginnt mit",
    "Type": "Typ", 
     "Date": "Ausstellungsdatum",
    "Country From": "Herkunftsland",
    "Country To": "Zielland",
    "Content type": "Inhaltstyp",
    "Internal Credit Note No.": "Interne Gutschriftnummer",
    "Select file type": "Dateityp auswählen",
    "Signature": "Signatur anzeigen",
    "Verification report": "Verifikationsbericht anzeigen",
    "Additional File": "Zusätzliche Datei",
    "Print": "Drucken",
    "Credit note": "Gutschrift",
    "Consi credit note": "Konsignationsgutschrift",
    "New": "neu",
    "Read": "gelesen",
    "Downloaded": "heruntergeladen",
    "IDOC":"IDOC",
    "You cannot select more than ": "Sie können nicht mehr als ",
    " files!": " Dateien auswählen!",
    "Help": "Hilfe"
  },
};
