import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;
const Pane = styled.div`
  flex: ${(props) => props.weight};
`;

export const QuarterSpliltScreen = ({
  children,
  firstWeight = 1,
  secondWeight = 1,
  thirdWeight = 1,
  fourthWeight = 1,
}) => {
  const [first, second, third, fourth] = children;
  return (
    <Container>
      <Pane weight={firstWeight}>{first}</Pane>
      <Pane weight={secondWeight}>{second}</Pane>
      <Pane weight={thirdWeight}>{third}</Pane>
      <Pane weight={fourthWeight}>{fourth}</Pane>
    </Container>
  );
};
