import React from "react";
import logoImage from "../assets/logo.png";

export const Logo = () => {
  return (
    <div className="app-logo">
      <img src={logoImage} alt="Logo" className="logo-image" />
    </div>
  );
};
